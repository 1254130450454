import * as React from "react";
import AppBar from "@mui/material/AppBar";
import { Typography } from "@mui/material";

function Nav() {
  return (
    <>
      <AppBar position="fixed" color="transparent" sx={{ boxShadow: 0 }}>
        <Typography variant="body1" p={2}>health.intel</Typography>
      </AppBar>
    </>
  );
}
export default Nav;
