import { CssBaseline } from "@mui/material";
import Mindmap from "./Mindmap";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Nav from "./Nav";
import Footer from "./Footer";

function App() {
  const theme = createTheme({
    palette: {
      background: {
        default: "#E9EAEC" //"#F3F5F6" //"#F3F5F6" //#F7F6F6
      },
      //mode:'dark',
      //Change Primary Color
      primary: {
        //main: "#CC5500",
        main: "#FAD02C" //"#4C5270"//"#b01f32" //"#b01f32", //#BB285C
        //main: "#AA5550"
      },
      secondary: {
        main: "#90ADC6" //"#BCECE0" //"#d2dee8" //"#b1c5d7" //"#90ADC6" //"#BCECE0"
      },
      success: {
        main: "#333652"
      }
      
    },
    typography: {
      fontFamily: [
        '"Fira Code"',
        "-apple-system",
        "BlinkMacSystemFont",
        '"Segoe UI"',
        "Roboto",
        '"Helvetica Neue"',
        "Arial",
        "sans-serif",
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(","),
    },
  });
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Nav />
      <Mindmap />
      <Footer />
    </ThemeProvider>
  );
}

export default App;
