import React, { useState, useRef } from "react";
import { Container, Typography, Paper, Grid } from "@mui/material";
import bi from "./assets/business.png";
import bino from "./assets/binoculars.png";
import ml from "./assets/ml.png";
import cloud from "./assets/server.png";
import message from "./assets/message-smaller.svg";
import vision from "./assets/telescope.png";
import dhis2 from "./assets/dhis2-logo.svg";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

const Mindmap = () => {
  const theme = useTheme();
  const screenSizeGTmd = useMediaQuery(theme.breakpoints.up("md"));

  const boxA = useRef(null);
  const boxB = useRef(null);
  const boxC = useRef(null);
  const boxD = useRef(null);
  const boxE = useRef(null);
  const boxF = useRef(null);
  const boxG = useRef(null);
  const visionImg = useRef(null);
  const envelopeImg = useRef(null);
  const biImg = useRef(null);
  const imgTwo = useRef(null);
  const shortTextA = useRef(null);
  const longTextA = useRef(null);
  const shortTextB = useRef(null);
  const longTextB = useRef(null);

  const [isBoxAMaximized, setIsBoxAMaximized] = useState(false);

  const resizeBoxA = () => {
    if (!isBoxAMaximized) {
      //boxA.current.style.zIndex = 0;
      boxB.current.style.zIndex = 0;
      boxC.current.style.zIndex = 0;
      boxD.current.style.zIndex = 0;
      boxE.current.style.zIndex = 0;
      boxF.current.style.zIndex = 0;
      boxG.current.style.zIndex = 0;

      boxA.current.style.width = "100%";
      boxA.current.style.height = "100%";
      boxA.current.style.zIndex = 1;
      visionImg.current.style.height = "400px";
      shortTextA.current.style.display = "none";
      setTimeout(() => {
        longTextA.current.style.display = "block";
      }, 450);

      setIsBoxAMaximized(true);
    } else {
      boxA.current.style.width = "24.5%";
      boxA.current.style.height = "49%";
      visionImg.current.style.height = "150px";
      shortTextA.current.style.display = "block";
      longTextA.current.style.display = "none";
      setIsBoxAMaximized(false);
    }
  };
  const resizeBoxB = () => {
    if (!isBoxAMaximized) {
      boxA.current.style.zIndex = 0;
      //boxB.current.style.zIndex = 0;
      boxC.current.style.zIndex = 0;
      boxD.current.style.zIndex = 0;
      boxE.current.style.zIndex = 0;
      boxF.current.style.zIndex = 0;
      boxG.current.style.zIndex = 0;

      boxB.current.style.width = "100%";
      boxB.current.style.height = "100%";
      boxB.current.style.zIndex = 1;
      boxB.current.style.left = 0;
      imgTwo.current.style.height = "400px";
      shortTextB.current.style.display = "none";
      setTimeout(() => {
        longTextB.current.style.display = "block";
      }, 450);
      setIsBoxAMaximized(true);
    } else {
      boxB.current.style.width = "24%";
      boxB.current.style.height = "29%";
      boxB.current.style.left = "25.5%";
      imgTwo.current.style.height = "100px";
      shortTextB.current.style.display = "block";
      longTextB.current.style.display = "none";
      setIsBoxAMaximized(false);
    }
  };
  const resizeBoxC = () => {
    if (!isBoxAMaximized) {
      boxA.current.style.zIndex = 0;
      boxB.current.style.zIndex = 0;
      //boxC.current.style.zIndex = 0;
      boxD.current.style.zIndex = 0;
      boxE.current.style.zIndex = 0;
      boxF.current.style.zIndex = 0;
      boxG.current.style.zIndex = 0;

      boxC.current.style.width = "100%";
      boxC.current.style.height = "100%";
      boxC.current.style.zIndex = 1;
      boxC.current.style.left = 0;
      setIsBoxAMaximized(true);
    } else {
      boxC.current.style.width = "24%";
      boxC.current.style.height = "59%";
      boxC.current.style.left = "50.5%";
      setIsBoxAMaximized(false);
    }
  };

  const resizeBoxD = () => {
    if (!isBoxAMaximized) {
      boxA.current.style.zIndex = 0;
      boxB.current.style.zIndex = 0;
      boxC.current.style.zIndex = 0;
      //boxD.current.style.zIndex = 0;
      boxE.current.style.zIndex = 0;
      boxF.current.style.zIndex = 0;
      boxG.current.style.zIndex = 0;

      boxD.current.style.width = "100%";
      boxD.current.style.height = "100%";
      boxD.current.style.zIndex = 1;
      boxD.current.style.left = 0;
      setIsBoxAMaximized(true);
    } else {
      boxD.current.style.width = "24.5%";
      boxD.current.style.height = "59%";
      boxD.current.style.left = "75.5%";
      setIsBoxAMaximized(false);
    }
  };

  const resizeBoxE = () => {
    if (!isBoxAMaximized) {
      boxA.current.style.zIndex = 0;
      boxB.current.style.zIndex = 0;
      boxC.current.style.zIndex = 0;
      boxD.current.style.zIndex = 0;
      //boxE.current.style.zIndex = 0;
      boxF.current.style.zIndex = 0;
      boxG.current.style.zIndex = 0;

      boxE.current.style.width = "100%";
      boxE.current.style.height = "100%";
      boxE.current.style.zIndex = 1;
      boxE.current.style.left = 0;
      boxE.current.style.top = 0;
      setIsBoxAMaximized(true);
    } else {
      boxE.current.style.width = "24.5%";
      boxE.current.style.height = "49%";
      boxE.current.style.top = "51%";
      setIsBoxAMaximized(false);
    }
  };

  const resizeBoxF = () => {
    if (!isBoxAMaximized) {
      boxA.current.style.zIndex = 0;
      boxB.current.style.zIndex = 0;
      boxC.current.style.zIndex = 0;
      boxD.current.style.zIndex = 0;
      boxE.current.style.zIndex = 0;
      //boxF.current.style.zIndex = 0;
      boxG.current.style.zIndex = 0;

      boxF.current.style.width = "100%";
      boxF.current.style.height = "100%";
      boxF.current.style.zIndex = 1;
      boxF.current.style.left = 0;
      boxF.current.style.top = 0;
      biImg.current.style.height = "400px";
      setIsBoxAMaximized(true);
    } else {
      boxF.current.style.width = "24%";
      boxF.current.style.height = "69%";
      boxF.current.style.left = "25.5%";
      boxF.current.style.top = "31%";
      biImg.current.style.height = "100px";
      setIsBoxAMaximized(false);
    }
  };

  const resizeBoxG = () => {
    if (!isBoxAMaximized) {
      boxA.current.style.zIndex = 0;
      boxB.current.style.zIndex = 0;
      boxC.current.style.zIndex = 0;
      boxD.current.style.zIndex = 0;
      boxE.current.style.zIndex = 0;
      boxF.current.style.zIndex = 0;
      //boxG.current.style.zIndex = 0;

      boxG.current.style.width = "100%";
      boxG.current.style.height = "100%";
      boxG.current.style.zIndex = 1;
      boxG.current.style.left = 0;
      boxG.current.style.top = 0;
      envelopeImg.current.style.height = "500px";
      setIsBoxAMaximized(true);
    } else {
      boxG.current.style.width = "49.5%";
      boxG.current.style.height = "39%";
      boxG.current.style.left = "50.5%";
      boxG.current.style.top = "61%";
      envelopeImg.current.style.height = "200px";
      setIsBoxAMaximized(false);
    }
  };

  return (
    <Container maxWidth="lg">
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        style={{ minHeight: "100vh" }}
      >
        <Grid item xl={12} lg={12} md={12} sm={12}>
          <Grid container spacing={1} style={{ height: "65vh", position: "relative" }} >
            <Grid item
              ref={boxA}
              sx={{
                width: "24.5%",
                height: "49%",
                transition: "left 0.5s ease,height 0.5s ease, width 0.5s ease",
                position: "absolute",
                top: 0,
                left: 0,
                //border: 5,
                //borderColor: "white",
                borderRadius: 2,
                boxShadow: 4,
                backgroundColor: theme.palette.secondary.main,
                "&:hover": {
                  cursor: "pointer",
                  boxShadow: 8,
                  //zIndex: '10' TODO
                },
              }}
              onClick={resizeBoxA}
            >
              <Paper elevation={0}>
                <div
                  ref={longTextA}
                  style={{
                    position: "absolute",
                    width: "60%",
                    top: 10,
                    display: "none",
                  }}
                >
                  <Typography variant="h5" gutterBottom color="primary">
                    OUR VISION //
                  </Typography>
                  <Typography variant="body1" gutterBottom>
                    At Health Intel, our vision is to revolutionize the
                    landscape of health informatics, shaping a future where
                    technology seamlessly integrates with healthcare to improve
                    patient outcomes and drive overall well-being.{" "}
                  </Typography>
                  {/*<Typography variant="body1" gutterBottom>
                    We envision a world where healthcare organizations harness
                    the power of data to unlock transformative insights, leading
                    to more personalized and proactive care. Through our
                    innovative consulting services, we strive to empower
                    healthcare providers to navigate the complexities of health
                    informatics with confidence, enabling them to seamlessly
                    capture, analyze, and utilize data to drive informed
                    decision-making.{" "}
                  </Typography>*/}

                  {/*We are committed to fostering a culture of innovation and collaboration, where cutting-edge technologies and advanced analytics converge to enhance healthcare delivery across the globe. 
                        By driving the adoption of interoperable systems, safeguarding patient data, and embracing emerging technologies, 
                        we aim to create a future where healthcare organizations are empowered to deliver superior care, optimize operational efficiency, and ultimately improve the health and well-being of individuals 
                        and communities worldwide.*/}
                  <Typography variant="h6" color="primary">
                    Innovation
                  </Typography>
                  <Typography variant="body2" gutterBottom>
                    We value innovation as a driving force behind our vision. We
                    strive to continually push the boundaries of health
                    informatics, exploring new technologies, methodologies, and
                    solutions that have the potential to revolutionize
                    healthcare.
                  </Typography>
                  <Typography variant="h6" color="primary">
                    Collaboration
                  </Typography>
                  <Typography variant="body2" gutterBottom>
                    We believe in the power of collaboration and recognize that
                    meaningful progress in health informatics requires
                    collective effort. {/*We actively engage with healthcare
                    providers, industry experts, and technology partners to
                    foster collaborative relationships that facilitate knowledge
                    sharing and the exchange of ideas. By working together, we
                    can address complex challenges, develop comprehensive
                    solutions, and achieve impactful outcomes that positively
                    influence the healthcare ecosystem.*/}
                  </Typography>
                  <Typography variant="h6" color="primary">
                    Empowerment
                  </Typography>
                  <Typography variant="body2" gutterBottom>
                    Our commitment to empowerment underlies everything we do. We
                    are dedicated to empowering healthcare organizations by
                    equipping them with the knowledge, tools, and resources
                    needed to navigate the evolving landscape of health
                    informatics. {/*We empower individuals within these
                    organizations to embrace technology, harness data-driven
                    insights, and make informed decisions that drive positive
                    change. By empowering our clients, we aim to contribute to a
                    healthcare environment where patients receive higher-quality
                    care, providers achieve greater operational efficiency, and
                    the overall health outcomes are enhanced.*/}
                  </Typography>
                </div>
                <div
                  ref={shortTextA}
                  style={{
                    position: "absolute",
                    bottom: 5,
                    transition: "display 0.5s ease",
                  }}
                >
                  <Typography variant="h3" color="primary">
                    01
                  </Typography>
                  <Typography variant="overline">VISION & VALUES</Typography>
                </div>
                <div>
                  <img
                    ref={visionImg}
                    src={vision}
                    style={{
                      height: "150px",
                      transition: "height 0.5s ease",
                      position: "absolute",
                      bottom: 5,
                      right: 5,
                    }}
                    alt=""
                  />
                </div>
              </Paper>
            </Grid>
            <Grid item
              ref={boxB}
              sx={{
                width: "24%",
                height: "29%",
                transition: "left 0.5s ease,height 0.5s ease, width 0.5s ease",
                position: "absolute",
                top: 0,
                left: "25.5%",
                //border: 5,
                //borderColor: "white",
                boxShadow: 4,
                borderRadius: 2,
                backgroundColor: theme.palette.secondary.main,
                "&:hover": {
                  cursor: "pointer",
                  boxShadow: 8
                },
              }}
              onClick={resizeBoxB}
            >
              <div className="row">
                <div
                  ref={longTextB}
                  style={{
                    position: "absolute",
                    width: "60%",
                    top: 10,
                    display: "none",
                  }}
                >
                  <Typography variant="h5" gutterBottom color="primary">
                    CLOUD //
                  </Typography>
                  <Typography variant="body1" gutterBottom>
                    Health Intel is a leading provider of comprehensive cloud
                    computing consulting services, dedicated to guiding
                    businesses through their transformation to the cloud.
                  </Typography>
                  <Typography variant="body1" gutterBottom>
                    With a team of certified cloud experts and extensive
                    industry experience, we empower organizations to leverage
                    the full potential of cloud technologies, optimizing
                    performance, security, and scalability.{" "}
                  </Typography>

                  <Typography variant="h6" color="primary">
                    Our Services //
                  </Typography>
                  <Typography variant="body2" gutterBottom>
                    <strong>Strategy:</strong> Tailored cloud roadmaps aligning
                    with your business objectives. {/*We assess your current IT
                    infrastructure, analyze your goals, and devise a
                    comprehensive cloud strategy that maximizes efficiency,
                    scalability, and cost-effectiveness.*/}
                  </Typography>
                  <Typography variant="body2" gutterBottom>
                    <strong>Migration:</strong> Seamlessly migrate your
                    applications and data to the cloud. {/*Our skilled consultants
                    ensure a smooth transition, minimizing downtime and
                    mitigating potential risks, while maximizing performance and
                    minimizing disruption.*/}
                  </Typography>
                  <Typography variant="body2" gutterBottom>
                    <strong>Implementation:</strong> Expertly deploy cloud
                    solutions for improved efficiency. {/*We handle the entire
                    implementation process, from selecting the right cloud
                    platform to configuring applications and integrating data,
                    ensuring a fully optimized cloud environment.*/}
                  </Typography>
                  <Typography variant="body2" gutterBottom>
                    <strong>Security:</strong> Robust security measures to
                    protect your cloud assets. {/*Our team employs industry-leading
                    security practices, including data encryption, access
                    controls, and continuous monitoring, to safeguard your
                    sensitive information from threats and breaches.*/}
                  </Typography>
                  <Typography variant="body2" gutterBottom>
                    <strong>Optimization:</strong> Continuously optimize your
                    cloud resources for cost-effectiveness. {/*Through regular
                    performance monitoring and analysis, we identify
                    opportunities to optimize your cloud infrastructure,
                    ensuring you get the most value out of your investment.*/}
                  </Typography>
                  <Typography variant="body2" gutterBottom>
                    <strong>Managed Services:</strong> Ongoing support and
                    maintenance for peace of mind. Our managed cloud services
                    provide round-the-clock monitoring, proactive issue
                    resolution, and regular updates to keep your cloud
                    environment running smoothly.
                  </Typography>
                </div>
                <div
                  ref={shortTextB}
                  style={{ position: "absolute", bottom: 5 }}
                >
                  <Typography variant="h3" color="primary">
                    02
                  </Typography>
                  <Typography variant="overline">
                    CLOUD COMPUTING SOLUTIONS
                  </Typography>
                </div>
                <div>
                  <img
                    ref={imgTwo}
                    src={cloud}
                    alt=""
                    style={{
                      height: "100px",
                      transition: "height 0.5s ease",
                      position: "absolute",
                      bottom: 15,
                      right: 5,
                    }}
                  />
                </div>
              </div>
            </Grid>
            <Grid item
              ref={boxC}
              sx={{
                width: "24%",
                height: "59%",
                transition: "left 0.5s ease,height 0.5s ease, width 0.5s ease",
                position: "absolute",
                top: 0,
                left: "50.5%",
                //border: 5,
                //borderColor: "white",
                borderRadius: 2,
                boxShadow: 4,
                backgroundColor: theme.palette.secondary.main,
                "&:hover": {
                  cursor: "pointer",
                  boxShadow: 8,
                },
              }}
              onClick={resizeBoxC}
            >
              <div className="row">
                <div style={{ position: "absolute", bottom: 5 }}>
                  <Typography variant="h3" color="primary">
                    03
                  </Typography>
                  <Typography variant="overline">HealthStats</Typography>
                </div>
                <div>
                  <img //ref={visionImg}
                    src={bino}
                    alt=""
                    style={{
                      height: "100px",
                      transition: "height 0.5s ease",
                      position: "absolute",
                      bottom: 5,
                      right: 5,
                    }}
                  />
                </div>
              </div>
            </Grid>
            <Grid item
              ref={boxD}
              sx={{
                width: "24.5%",
                height: "59%",
                transition: "left 0.5s ease,height 0.5s ease, width 0.5s ease",
                position: "absolute",
                top: 0,
                left: "75.5%",
                //border: 5,
                //borderColor: "white",
                boxShadow: 4,
                borderRadius: 2,
                backgroundColor: theme.palette.secondary.main,
                "&:hover": {
                  cursor: "pointer",
                  boxShadow: 8
                },
              }}
              onClick={resizeBoxD}
            >
              <div className="row">
                <div style={{ position: "absolute", bottom: 5 }}>
                  <Typography variant="h3" color="primary">
                    04
                  </Typography>
                  <Typography variant="overline">Machine Learning</Typography>
                </div>
                <div>
                  <img //ref={visionImg}
                    src={ml}
                    alt=""
                    style={{
                      height: "100px",
                      transition: "height 0.5s ease",
                      position: "absolute",
                      bottom: 5,
                      right: 5,
                    }}
                  />
                </div>
              </div>
            </Grid>
            <Grid item
              ref={boxE}
              sx={{
                width: "24.5%",
                height: "49%",
                transition:
                  "left 0.5s ease, top 0.5s ease, height 0.5s ease, width 0.5s ease",
                position: "absolute",
                top: "51%",
                left: 0,
                //border: 5,
                //borderColor: "white",
                boxShadow: 4,
                borderRadius: 2,
                backgroundColor: theme.palette.secondary.main,
                "&:hover": {
                  cursor: "pointer",
                  boxShadow: 8
                },
              }}
              onClick={resizeBoxE}
            >
              <div className="row">
                <div style={{ position: "absolute", bottom: 5 }}>
                  <Typography variant="h3" color="primary">
                    05
                  </Typography>
                  <Typography variant="overline">DHIS2</Typography>
                </div>
                <div>
                  <img //ref={visionImg}
                    src={dhis2}
                    alt=""
                    style={{
                      height: "100px",
                      transition: "height 0.5s ease",
                      position: "absolute",
                      bottom: 5,
                      right: 5,
                    }}
                  />
                </div>
              </div>
            </Grid>
            <Grid item
              ref={boxF}
              sx={{
                width: "24%",
                height: "69%",
                transition:
                  "left 0.5s ease, top 0.5s ease, height 0.5s ease, width 0.5s ease",
                position: "absolute",
                top: "31%",
                left: "25.5%",
                //border: 5,
                //borderColor: "white",
                borderRadius: 2,
                backgroundColor: theme.palette.secondary.main,
                boxShadow: 4,
                "&:hover": {
                  cursor: "pointer",
                  boxShadow: 8
                },
              }}
              onClick={resizeBoxF}
            >
              <div className="row">
                <div style={{ position: "absolute", bottom: 5 }}>
                  <Typography variant="h3" color="primary">
                    06
                  </Typography>
                  <Typography variant="overline">
                    Business Intelligence
                  </Typography>
                </div>

                <div>
                  <img
                    ref={biImg}
                    src={bi}
                    alt=""
                    style={{
                      height: "100px",
                      transition: "height 0.5s ease",
                      position: "absolute",
                      bottom: 20,
                      right: 5,
                    }}
                  />
                </div>
              </div>
            </Grid>
            <Grid item
              ref={boxG}
              sx={{
                width: "49.5%",
                height: "39%",
                transition:
                  "left 0.5s ease, top 0.5s ease, height 0.5s ease, width 0.5s ease",
                position: "absolute",
                top: "61%",
                left: "50.5%",
                //border: 5,
                //borderColor: "white",
                borderRadius: 2,
                backgroundColor: theme.palette.primary.main,
                boxShadow: 4,
                "&:hover": {
                  cursor: "pointer",
                  boxShadow: 8
                },
              }}
              onClick={resizeBoxG}
            >
              <div className="row">
                <div style={{ position: "absolute", bottom: 5 }}>
                  <Typography variant="h3" style={{color: "#333652"}}>07</Typography>
                  <Typography variant="overline" style={{color: "#333652"}}>Connect with Us</Typography>
                </div>

                <div>
                  <img
                    ref={envelopeImg}
                    src={message}
                    alt=""
                    style={{
                      height: "200px",
                      transition: "height 0.5s ease",
                      position: "absolute",
                      bottom: 5,
                      right: 5,
                    }}
                  />
                </div>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Mindmap;
